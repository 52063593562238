/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useMemo } from 'react'
import '../assets/scss/ListingPage.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Carousel } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import TopMenuFilter from '../components/TopMenuFilter';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'
import { serverUrl, landlordId } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
function ListingProperty(props) {
    const { id } = useParams();
    // loading status
    const location = useLocation();

    const [dataFromChild, setDataFromChild] = useState('');
    // Function to handle data from the child
    const handleChildData = (childData) => {
        setDataFromChild(childData);
    };
    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };

    useEffect(() => {
        // Scroll to the top of the page when the route changes
        window.scrollTo(0, 0);
    }, [location]);

    const [isLoading, setIsLoading] = useState(true)


    const [landlordApartment, setLandlordApartment] = useState([])

    const [countApartment, setCountApartment] = useState('')
    const accessToken = localStorage.getItem("authTokens");
    if (accessToken) {
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    }
    const [currentUser, setCurrentUser] = useState([])

    // get the searched data
    useEffect(() => {
        //  serverUrl.get(`/landlord/all-landlords?landlord_id=${landlordId}`)
        // .then((res) => {
        //     const listedApartments = res.data.results.filter((apartment) => apartment.is_listed === true);
        //     setLandlordApartment(listedApartments);
        //     setCountApartment(res.data.count);

        //     // Loop through the listed apartments and check if they have houses
        //     listedApartments.forEach((apartment) => {
        //         serverUrl.get(`/landlord/alliance-house?property_id=${apartment.id}`)
        //             .then((housesRes) => {
        //                 const hasHouses = housesRes.data.results.length > 0;
        //                 console.log(`Apartment  is found ${apartment.id} has houses: ${hasHouses} ${listedApartments}`);

        //                 // You can do something with this information, e.g., update state.
        //             })
        //             .catch((housesError) => {
        //                 console.log(`Error fetching houses for apartment ${apartment.id}:`, housesError);
        //             });
        //     });
        // })
        // .catch((error) => {
        //     console.log(error);
        // });

        serverUrl.get(`/landlord/all-landlords`)
            .then((res) => {
                const listedApartments = res.data.results.filter((apartment) => apartment.is_listed === true);
                setCountApartment(res.data.count);
                console.log(listedApartments)
                Promise.all(listedApartments.map((apartment) => {
                    return serverUrl.get(`/landlord/alliance-house?property_id=${apartment.id}`)
                        .then((housesRes) => {
                            const hasHouses = housesRes.data.results.length > 0;
                            if (hasHouses) {
                                return apartment;
                            }
                        })
                        .catch((housesError) => {
                            console.log(`Error fetching houses for apartment ${apartment.id}:`, housesError);
                        });
                }))
                    .then((apartmentsWithHouses) => {
                        setLandlordApartment(apartmentsWithHouses.filter(Boolean));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });


        if (accessToken) {
            // check first if user is logged in
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            // get the current user
            serverUrl.get(`/users/users-details/${userId}/`, config)
                .then((res => {
                    setCurrentUser(res.data)
                }))
                .catch((error) => {
                    console.log(error)
                })
            // Fetch liked units for the user based on their userId.
            serverUrl
                .get(`/landlord/viewers-favourites/?user=${userId}`, config)
                .then((res) => {
                    // Process the response to determine which units are liked by the user.
                    const likedApartment = res.data.results.map((likedApartment) => likedApartment.apartment);


                    // Update the heartState based on the likedUnits.
                    const newHeartState = { ...heartState };
                    likedApartment.forEach((apartmentId) => {
                        newHeartState[apartmentId] = true;
                    });
                    setHeartState(newHeartState);

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [])



    // filter based on the property location

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((location) => {
        if (!uniquePropertyLocation.has(location.location)) {
            uniquePropertyLocation.add(location.location);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };
    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }
    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected property type if dataFromChild is not blank
        if (dataFromChild && item.type_of_apartment !== dataFromChild) {
            return false;
        }

        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        return true;
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState({});
    const [showIndicators, setShowIndicators] = useState(false);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const toggleIndicators = () => {
        setShowIndicators(!showIndicators);
    };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const uniqueRandomImages = [];

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/Property/apartment${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [landlordApartment, backgroundImageApartment]);

    const renderCarouselItems = (apartmentId) => {
        // const uniqueImages = backgroundImageApartment[apartmentId] || [];
        const uniqueImages = landlordApartment.filter((apartment) => apartment.id === apartmentId)
        const Images = uniqueImages[0].property_image
        return Images.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const [heartState, setHeartState] = useState({});


    const toggleHearts = async (apartmentId) => {
        const prevHeartStateValue = heartState[apartmentId];

        // Update the UI to immediately reflect the change.
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [apartmentId]: !prevHeartState[apartmentId],
        }));

        if (accessToken) {
            // Check if the user is logged in.
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;

            // Headers access token.
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            };

            // Decoding the token to get the user id.
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;

            // If the user has already liked the apartment, delete it.
            if (prevHeartStateValue) {
                try {
                    const res = await serverUrl.get(`/landlord/viewers-favourites/?apartment=${apartmentId}&user=${userId}`, config);
                    if (res.data.count === 1) {
                        // If the user has liked the apartment, delete it.
                        await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                // If the user hasn't liked the apartment, add it.
                const likedApartment = {
                    apartment: apartmentId,
                    user: userId,
                    user_name: currentUser.full_name
                };
                try {
                    await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            toast.error('Kindly Login so that you can be able to like apartment');
        }
    };




    return (
        <>
            <NavigationBar onData={handleSearchData} {...props} />
            {/* <TopMenuFilter onData={handleChildData} {...props} /> */}

            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties mt-3'> ALL PROPERTIES</p>



                </div>
                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {countApartment === 0 || !displayedItems || landlordApartment.length === 0 ?
                        <p className='notfoundp'>Sorry no property is listed at the moment </p>
                        :
                        displayedItems.map((apartmentDetail) =>

                            <Card key={apartmentDetail.id} className='house'>
                                <Card.Body>

                                    <div className="image-container">
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <Slider {...settings} interval={null}>{renderCarouselItems(apartmentDetail.id)}</Slider>
                                        </Link>
                                        <div className='heartBadge' onClick={() => toggleHearts(apartmentDetail.id)}>
                                            {heartState[apartmentDetail.id] ? (
                                                <img src={Heart} alt="Heart" />
                                            ) : (
                                                <img src={Heart2} alt="Heart2" />
                                            )}
                                        </div>

                                        {/* <div className="badge">{apartmentDetail.total_units} Units</div> */}
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <div className='d-flex justify-content-between'>
                                                <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.7rem" }}>{apartmentDetail.location},{apartmentDetail.county}</p>
                                            </div>
                                        </Link>

                                    </div>
                                    <p className='houseName' style={{ clear: "left", margin: "0", marginTop: "-1rem" }}>{apartmentDetail.name}</p>
                                    <p></p>
                                </Card.Body>
                            </Card>
                        )
                    }



                </div>

                {/* the pagination of the cards */}
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginBottom: "2rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}

            </div >
            <Button className='text-white bg-dark border-0 bottomButtonMap'>
                <Link to={'/map/listing'} className='text-white text-decoration-none'>
                    Show map
                    <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                </Link>

            </Button>

        </>
    )
}

export default ListingProperty