/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../assets/scss/CompetitiveFaq.scss'
import RealAgent from '../RealAgent'

function CompetitiveFaq() {
    return (
        <div className='competitivefaq'>
            {/* <Row className='topRow'> */}
            <Row className=''>

           
            </Row>

            {/* FAQ */}
            <div className='faqContainer'>
                <h2 className='text-start'>FREQUENTLY ASKED QUESTIONS</h2>
                <Row>
                    <Col md={6} sm={12} xm={12}>
                        <div>
                            <h3>As a landlord how to get my property managed by Alliance Realtors?</h3>
                            <p>Our services are tailored based on our client needs </p>
                            <Link>Kindly Contact Us <i class="bi bi-arrow-right"></i></Link>
                        </div>

                        <div>
                            <h3>I want my property managed by alliance what are your charges?</h3>
                            <p>Kindly contact us for more info regarding your question</p>
                            <Link>Contact Us<i class="bi bi-arrow-right"></i></Link>
                        </div>

                        <div className='d-none'>
                            <h3>Landlord contracts?</h3>
                            <p>To be provided</p>
                            <Link>Get Further Assistance <i class="bi bi-arrow-right"></i></Link>
                        </div>
                    </Col>

                    <Col md={6} sm={12} xm={12}>
                        <div>
                            <h3>Is my rent deposit refundable?</h3>
                            <p>All deposits are refundable in line with your tenancy lease agreement</p>
                            <Link>For more info <a tel='0720144565'>contact us</a> <i className='bi bi-house'></i></Link>
                        </div>

                        <div>
                            <h3>I have trouble managing my rent expense what can i do to be paying my rent on time?</h3>
                            <p>Always priotize shelter as a basic need</p>
                            <Link>Get Further Assistance <i className='bi bi-house'></i></Link>
                        </div>

                        <div>
                            <h3>What is the minimum period for vacating notice?</h3>
                            <p>The vacating notice is custom to your lease, however the most common period are 30 days for rent property and 60 days for commercials</p>

                            <Link>Get Further Assistance <i className='bi bi-house'></i></Link>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* <RealAgent/> */}

        </div>
    )
}

export default CompetitiveFaq