/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { renderToString } from 'react-dom/server';
import { useParams, Link } from "react-router-dom";
import CustomLabelImage from '../assets/images/custom-marker.png';

const MapComponent = ({ google, location }) => {
  const { latitude, longitude } = location;
  const { id } = useParams();
  const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
  const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });


  useEffect(() => {
    setMapCenter({ lat: latitude, lng: longitude });
    setMarkerPosition({ lat: latitude, lng: longitude });

  }, [latitude, longitude]);


  const [isOpen, setIsOpen] = useState(false);

  // const handleMarkerClick = () => {
  //   setIsOpen(!isOpen);
  // };
  const [openInfoWindowIndex, setOpenInfoWindowIndex] = useState(false);

  const handleMarkerClick = () => {
    setOpenInfoWindowIndex(true);

  };
  const handleInfoWindowClose = () => {
    setOpenInfoWindowIndex(false);
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'static' }}>
      <Map
        google={google}
        className='position-relative'
        zoom={10}
        center={mapCenter}
        style={{ height: '80vh', width: '100%', position: 'relative' }}
      >

        <Marker
          position={markerPosition}
          onClick={handleMarkerClick}
          icon={{
            url: CustomLabelImage,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />

        <InfoWindow
          position={markerPosition}
          onClose={handleInfoWindowClose}
          visible={openInfoWindowIndex}

        >
          <div>
            <p>{location.name}</p>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};


export default GoogleApiWrapper({
  // apiKey: 'AIzaSyDLyN4rhdx4vyZ4PcJlWHXddEV2xaUVpmw',
  apiKey: 'AIzaSyBs50rN-7q3YU8ZfGa9hMyNGGZCTyiWU0s'
})(MapComponent);


