/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container, Image, Offcanvas, FormControl, Dropdown, Modal, Button, Form, Spinner } from 'react-bootstrap';
import '../assets/scss/NavigationBar.scss';
import Logo from '../assets/images/logo_alliance.png'
import Logo2 from '../assets/images/alliance_logo.png'
import { jwtDecode } from 'jwt-decode';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom';
import { serverUrl } from '../serverUrl';
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import Input from 'react-phone-number-input/input'


function NavigationBar() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // the dropdown login part
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [showUserLoginModal, setShowUserLoginModal] = useState(false);
  const [showUserRegisterModal, setShowUserRegisterModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginDropdown(!showLoginDropdown);
  };

  const handleUserLoginClick = () => {
    setShowUserLoginModal(true);
    setShowLoginDropdown(false); // Close the dropdown
  };

  const handleCloseUserLoginModal = () => {
    setShowUserLoginModal(false);
  };


  const handleUserRegisterClick = () => {
    setShowUserRegisterModal(true);

  };

  const handleCloseUserRegisterModal = () => {
    setShowUserRegisterModal(false);
  };

  const [showUserLoginUserModal, setShowUserLoginUserModal] = useState(false);

  const handleUserLoginUserClick = () => {
    setShowUserLoginUserModal(true);

  };

  const handleCloseUserLoginUserModal = () => {
    setShowUserLoginUserModal(false);
  };

  const [value, setValue] = useState('');
  const [valuePhone, setValuePhone] = useState('');

  const handlePhoneInputChange = (phoneValue, country, e, formattedValue) => {
    setValue(phoneValue); // Update the state
  }

  function handleLogin(e) {
    // Prevent the form from redirecting to another page
    e.preventDefault();
    const cleanValue = value.replace(/[^\d+]/g, '');

    if (cleanValue.length > 10) {
      const newValue = '0' + cleanValue.slice(-9);
      setValuePhone(newValue)
      serverUrl.get(`/users/get-user/?phone=${newValue}`)
        .then((res) => {
          if (res.data.status !== false) {
            // if the user is not found then close the modal and open a new one for them to enter their names email and password
            handleUserRegisterClick()
            handleCloseUserLoginModal()
            setValue('')
          }
          else {
            // if the user is found then get to login them
            handleUserLoginUserClick()
            handleCloseUserLoginModal()
            setValue('')

          }
        })
    }
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
  let [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)

  function handleRegister(e) {
    // Prevent the form from redirecting to another page
    e.preventDefault();
    const data = {
      phone_number: valuePhone,
      full_name: formData.name,
      email: formData.email,
      password: formData.password,
    }
    const loginData = {
      phone_number: valuePhone,
      password: formData.password,

    }
    serverUrl.post(`/users/register/`, data)
      .then((res) => {
        // after user registers login the user
        serverUrl.post(`/users/login/`, loginData)
          .then((res) => {
            const data = res.data.data
            setAuthTokens(data.tokens)
            setUser(jwtDecode(data.tokens.access))
            console.log(setUser)
            localStorage.setItem('authTokens', JSON.stringify(data.tokens))
            // after the login is succesful then get to add them in the viewers table 
            const accessToken = localStorage.getItem("authTokens");
            // get the access token
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
              headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            const viewerData = {
              user: userId
            }
            // check if the user is in the viewers table if not add them if they are then pass
            serverUrl.get(`/landlord/viewers-leads/?user=${userId}`, config)
              .then((res) => {
                if (res.data.count === 0) {
                  // post the data to the viewers table
                  serverUrl.post(`/landlord/viewers-leads/`, viewerData, config)
                    .then((res) => {
                      handleCloseUserRegisterModal()
                      setFormData({
                        password: ''
                      })
                      window.location.reload()
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }
                else {
                  handleCloseUserRegisterModal()
                  setFormData({
                    password: ''
                  })
                }

              })
              .catch((error) => {
                console.log(error)
              })

          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error)
      })

      .catch((error) => {
        if (error.response.data.data.status_code === '400') {
          toast.error(error.response.data.data.error)
        }
        else {
          console.log(error)
        }
      })
  }

  function handleLoginUser(e) {
    // Prevent the form from redirecting to another page
    e.preventDefault();
    const loginData = {
      phone_number: valuePhone,
      password: formData.password,

    }
    serverUrl.post(`/users/login/`, loginData)
      .then((res) => {
        const data = res.data.data
        setAuthTokens(data.tokens)
        setUser(jwtDecode(data.tokens.access))
        localStorage.setItem('authTokens', JSON.stringify(data.tokens))
        // after the login is succesful then get to add them in the viewers table 
        const accessToken = localStorage.getItem("authTokens");
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
          headers: { Authorization: `Bearer ${access}` }
        }
        toast.success('Succesfully loged in')

        // decoding the token so that i can get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;

        serverUrl.get(`/landlord/alliance-viewer-lead?user_id=${userId}`, config)
          .then((res) => {
            setUserImage(res.data.results[0].user_name)
          })
          .catch((error) => {
            console.log(error)
          })

        const viewerData = {
          user: userId
        }
        // check if the user is in the viewers table if not add them if they are then pass
        serverUrl.get(`/landlord/viewers-leads/?user=${userId}`, config)
          .then((res) => {
            if (res.data.count === 0) {
              // post the data to the viewers table
              serverUrl.post(`/landlord/viewers-leads/`, viewerData, config)
                .then((res) => {
                  handleCloseUserLoginUserModal()
                  setFormData({
                    password: ''
                  })
                  window.location.reload()

                })
                .catch((error) => {
                  console.log(error)
                })
            }
            else {
              handleCloseUserLoginUserModal()
              setFormData({
                password: ''
              })
            }

          })
          .catch((error) => {
            console.log(error)
          })

      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  // get if the user is loged in then get the imafe
  const accessToken = localStorage.getItem("authTokens");

  const [userImage, setUserImage] = useState('')


  const handleLogout = () => {
    localStorage.removeItem('authTokens');
    toast.success('Successfully logged out!');

    // Check the current location and redirect if necessary
    const currentHashLocation = window.location.hash;

    // Add your conditions for redirecting based on the current location
    if (currentHashLocation === '#/favourites' || currentHashLocation === '#/booking') {
      // Redirect to the "/home" page
      window.location.href = '/';
    } else {
      // Reload the current page
      window.location.reload();
    }
  };

  const [feedbackData, setFeedbackData] = useState([])

  useEffect(() => {
    if (accessToken) {
      // get the access token
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` }
      }
      // decoding the token so that i can get the user id
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;
      serverUrl.get(`/landlord/alliance-viewer-lead?user_id=${userId}`, config)
        .then((res) => {
          setUserImage(res.data.results[0].user_name)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    // get the contacts feedback 
    serverUrl.get(`/landlord/contacts-feedback`)
      .then((res) => {
        setFeedbackData(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])
  const handleFavourite = () => {
    toast.error('Kindly Login so that you can see your favourites')
  }
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const currentDate = new Date();

  const [formDataTalk, setFormDataTalk] = useState({
    name: '',
    phone: '',
    feedback: '',
    tenantStatus: 'current',
    year: '',
    month: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormDataTalk((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();
    let feedbackTypeData = ''
    setIsLoading(true)

    if (formDataTalk.feedback) {
      feedbackTypeData = feedbackData.filter((feedback) => parseInt(feedback.id) === parseInt(formDataTalk.feedback))[0]
    }
    if (formDataTalk.tenantStatus === 'current') {

      const data = {
        name: formDataTalk.name,
        phone_number: formDataTalk.phone,
        feedback: formDataTalk.feedback,
        // contact_email: feedbackTypeData.contact_email,
        // contact_name: feedbackTypeData.contact_name,
        // tenant_status: formDataTalk.tenantStatus,
        year: currentDate.getFullYear().toString(),
        month: months[currentDate.getMonth()]
      }

      serverUrl.post(`landlord/feedbacks/`, data)
        .then((res) => {
          toast.success('Succesfully sent your feedback we will get back to you')
          setShowOffcanvas(false)
          setIsLoading(false)

          setFormDataTalk({
            name: '',
            phone: '',
            feedback: '',
            tenantStatus: 'current',
            year: '',
            month: '',
          })

        })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      const data = {
        name: formDataTalk.name,
        phone_number: formDataTalk.phone,
        feedback: feedbackTypeData.feedback,
        contact_email: feedbackTypeData.contact_email,
        contact_name: feedbackTypeData.contact_name,
        tenant_status: formDataTalk.tenantStatus,
        year: formDataTalk.year,
        month: formDataTalk.month
      }
      serverUrl.post(`landlord/feedbacks/`, data)
        .then((res) => {
          toast.success('Succesfully sent your feedback we will get back to you')
          setShowOffcanvas(false)
          setFormDataTalk({
            name: '',
            phone: '',
            feedback: '',
            tenantStatus: 'current',
            year: '',
            month: '',
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }





  };



  return (
    <Navbar
      expand="lg"
      className={`custom-navbar responsive-menu fixed-top navbarDisplay`}
    >
      <Container fluid className='topNavbarNavigation'>


        <Navbar.Collapse id="basic-navbar-nav " className='leftSideBar'>
          <Nav className="">
            <Nav.Link href="#home" className={`custom-link`}>
            </Nav.Link>
            <Nav.Link href="/" className={`custom-link`}>
              Home
            </Nav.Link>
            {/* <Button className='ms-2 btn btn-sm btn-success text-warning text-uppercase fw-bold' style={{fontSize:"20px"}}>
              <Link to={'/profile'} className='text-warning fw-bold text-decoration-none'>Get Started</Link>

            </Button> */}
            <Button className='ms-2 btn btn-sm btn-success text-warning text-uppercase fw-bold' style={{ fontSize: "20px" }} onClick={() => setShowOffcanvas(true)}>
              Talk To Us
            </Button>
          </Nav>
        </Navbar.Collapse>

        <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Contact Form</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formDataTalk.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone</Form.Label>
                <FormControl
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                  value={formDataTalk.phone}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>


              <Form.Group className="mb-3" controlId="feedback">
                <Form.Label>Feedback</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter your feedback"
                  name="feedback"
                  value={formDataTalk.feedback}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3 d-none" controlId="tenantStatus">
                <Form.Label>Tenant Status</Form.Label>
                <Form.Select
                  name="tenantStatus"
                  value={formDataTalk.tenantStatus}
                  onChange={handleInputChange}
                >
                  <option value="current">Current Tenant</option>
                  <option value="previous">Previous Tenant</option>
                </Form.Select>
              </Form.Group>
              {feedbackData.length !== 0 ?
                <Form.Group className="mb-3" controlId="tenantStatus">
                  <Form.Label>Feedback</Form.Label>

                  <Form.Select name="feedback" value={formDataTalk.feedback} onChange={handleInputChange}>
                    <option value="">Select feedback type</option>
                    {feedbackData.map((feedback) => (
                      <option value={feedback.id} style={{ textTransform: "capitalize" }}>{feedback.feedback}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
                :
                <></>
              }


              {formDataTalk.tenantStatus === 'previous' && (
                <>
                  <Form.Group className="mb-3" controlId="selectedMonth">
                    <Form.Label>Select Month</Form.Label>
                    <Form.Control
                      as="select"
                      value={formDataTalk.month}
                      onChange={handleInputChange}
                      name='month'
                    >
                      <option value="" disabled>Select a month</option>
                      {months.map((month, index) => (
                        <option key={index} value={month.toLowerCase()}>
                          {month.charAt(0).toUpperCase() + month.slice(1)}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="year">
                    <Form.Label>Year</Form.Label>
                    <FormControl
                      type="number"
                      placeholder="Enter the year"
                      name="year"
                      value={formDataTalk.year}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </>
              )}




              {isLoading ?
                <Button variant="primary">
                  <Spinner />
                </Button>
                :
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              }

            </Form>
          </Offcanvas.Body>
        </Offcanvas>



        <Navbar.Brand href="/" className={`custom-link`}>
          <Image src={Logo} className='imageLogoDisplayWhite d-none' />

          <Image src={Logo} className='imageLogoDisplay' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className={`custom-link`}>
            </Nav.Link>
            <Nav.Link href="#/listing-properties" className={`custom-link`}>
              Property Listing
            </Nav.Link>
            <Nav.Link href="#/services" className={`custom-link`}>
              Services
            </Nav.Link>
            <Nav.Link href="#/competitive" className={`custom-link`}>
              Our Advantage
            </Nav.Link>
            {/* <Nav.Link href="#/all-properties" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Properties
            </Nav.Link> */}
            <button
              className="loginAirbnb text-white p-2 bg-warning me-3"
              style={{
                borderRadius: "10px",
                cursor: accessToken ? "not-allowed" : "pointer",
              }}
              onClick={handleUserLoginClick}
              disabled={!!accessToken} 
            >
              {accessToken ? (
                <span>{userImage}</span>
              ) : (
                <>
                  <span className="bi bi-person-circle me-2"></span>
                  <span>Login</span>
                </>
              )}
            </button>




            {/* <Nav.Link href="https://arl.kodinyumba.com/#/login"  id="basic-nav-dropdown" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`} style={{backgroundColor:'#E6B800',color:"white"}}> */}
            {accessToken && (
              <Dropdown show={showLoginDropdown} onClose={() => setShowLoginDropdown(false)} className='loginAirbnb'>
                <Dropdown.Toggle
                  as={Nav.Link}
                  className={`custom-top-link loginIcon`}
                  onClick={handleLoginClick}
                >
                  <span className="bi bi-list me-2"></span>
                  <span className="bi bi-person-circle"></span>


                </Dropdown.Toggle>
                <Dropdown.Menu align="end">

                  {accessToken ?

                    <>
                      <Dropdown.Item href='/#/favourites' className='' >My favorites</Dropdown.Item>
                      {/* <Dropdown.Item href='/#/bookings' className='' style={{ borderBottom: "1px solid lightgray" }}>My Bookings</Dropdown.Item> */}
                    </>
                    :
                    <Dropdown.Item className='' onClick={handleFavourite} style={{ borderBottom: "1px solid lightgray" }}>My favorites</Dropdown.Item>


                  }
                  {/* <Dropdown.Item>Help Center</Dropdown.Item> */}
                  {accessToken ?
                    <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
                    :
                    <></>
                  }
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className='loginText'>Log in or sign up</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='loginForm'>
                <h5>Welcome to Alliance Realtors</h5>

                <Form onSubmit={handleLogin}>

                  <div className="phone-input-container mb-3">
                    <label htmlFor="phone-input">Country/Region</label>
                    <PhoneInput
                      id="phone-input"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="KE"
                      value={value}
                      onChange={handlePhoneInputChange}
                    // disabled

                    />
                    <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                    <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                  </div>
                  <Button variant="warning" className='text-white w-100' type="">
                    Continue
                  </Button>
                </Form>
              </Modal.Body>

            </Modal>

            {/* the register modal */}
            <Modal show={showUserRegisterModal} onHide={handleCloseUserRegisterModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className='loginText'>SIGN UP</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='loginForm'>
                <h5>Welcome to Alliance Realtors</h5>

                <Form onSubmit={handleRegister}>

                  <div className="phone-input-container mb-3">


                    <div className="input-icons">
                      <i className="fa fa-user icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        placeholder="Full Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="input-icons">
                      <i className="fa fa-envelope icon"></i>
                      <input
                        className="input-field"
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="input-icons">
                      <i className="fa fa-lock icon"></i>
                      <input
                        className="input-field"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                      </span>

                    </div>


                    <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                    <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                  </div>
                  <Button variant="warning" className='text-white w-100' type="">
                    Sign Up
                  </Button>
                </Form>
              </Modal.Body>

            </Modal>

            {/* the login user modal */}
            <Modal show={showUserLoginUserModal} onHide={handleCloseUserLoginUserModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className='loginText'>LOGIN</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='loginForm'>
                <h5>Welcome to Alliance Realtors kindly login</h5>

                <Form onSubmit={handleLoginUser}>

                  <div className="phone-input-container mb-3">
                    <div className="input-icons">
                      <i className="fa fa-lock icon"></i>
                      <input
                        className="input-field"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                        {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "13px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "13px" }} />}
                      </span>

                    </div>


                    <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                    <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                  </div>
                  <Button variant="warning" className='text-white w-100' type="">
                    Login
                  </Button>
                </Form>
              </Modal.Body>

            </Modal>

            <NavDropdown title="Dropdown" id="basic-nav-dropdown" className="custom-dropdown d-none">
              <NavDropdown.Item href="#action/3.1" className="custom-dropdown-item">
                Property
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className="custom-dropdown-item">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className="custom-dropdown-item">
                Something
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" className="custom-dropdown-item">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
