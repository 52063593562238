/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown } from 'react-bootstrap';
import '../assets/scss/SelectedProperty.scss'
import SideBar from '../components/SideMenu'
import Footer from '../components/Footer'
import NavigationBar from '../components/NavigationBar';
import { serverUrl } from '../serverUrl';
import { useParams, Link } from "react-router-dom";
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'
import Grid from '../assets/images/grid.png'
import TopMenuFilter from '../components/TopMenuFilter';
import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";
import loaderImage from '../assets/images/logo_1.png'
import Twitter from "../assets/images/twitter.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Mail from "../assets/images/gmail.png";
import Whatsapp from "../assets/images/whatsapp.png";
import Paperclip from "../assets/images/paperclip.png";

function SelectedProperty(props) {
    const { id } = useParams();
    const [dataFromChild, setDataFromChild] = useState('');
    // Function to handle data from the child
    const handleChildData = (childData) => {
        setDataFromChild(childData);
    };
    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };
    // loading status
    const [isLoading, setIsLoading] = useState(true)

    // after the login is succesful then get to add them in the viewers table 
    const accessToken = localStorage.getItem("authTokens");
    if (accessToken) {
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwtDecode(accessToken);
        const userId = decodedToken.user_id;
    }


    // apartment
    const [selectedApartment, setSelectedApartment] = useState([])
    const [apartmentImage, setApartmentImage] = useState("")
    // randomise for each House
    const [backgroundImages, setBackgroundImages] = useState({});
    const amenitiesApartment = [
        {
            name: "Court",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/basketball-court.png`
        },
        {
            name: "CCTV",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/cctv.png`
        },
        {
            name: "WiFi",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/wifi_1.png`
        },
        {
            name: "Balcony",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/balcony.png`
        },
        {
            name: "Gym",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/gym.png`
        },
    ]
    const [housesApartment, setHouseApartment] = useState([])

    const [selectedPropertyDetails, setSelectedProperty] = useState({})
    const [copied, setCopied] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    const handleShow = () => {
        setShowShareModal(true)
    }

    const handleClose = () => setShowShareModal(false);
    const [selectedHouses, setSelectedHouses] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedApartmentImages, setSelectedApartmentImages] = useState([]);

    const url = `https://alliancerealtors.kodinyumba.com/#/selected-property/${id}`;
    const photourl = `https://alliancerealtors.kodinyumba.com/#/all-photos-propert/${id}`;

    const handleCopyLink = () => {
        const textToCopy = `${url}`;
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = textToCopy;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        setCopied(true);
        toast.success("Linked is copied");
    };

    const messageLink = `Hi, see this property listed from Alliance Realtors! 😊 This beautiful property is now on the market and available for rent!
    📷 View Photos: ${photourl}
    🔗 Unit Link: ${url}`;

    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        messageLink
    )}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        messageLink
    )}`;

    const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        messageLink
    )}`;

    const instagramLink = "https://www.instagram.com/direct/inbox/";

    function shareFacebook() {
        let url = encodeURIComponent(messageLink);
        let title = "title";
        window.open(
            `http://www.facebook.com/sharer.php?u=${url}`,
            "pop up name",
            "width=600, height=600"
        );
    }

    // get the houses 
    const [nearbyFacilities, setNearbyFacilities] = useState([])
    const [heartState, setHeartState] = useState({});
    const [currentUser, setCurrentUser] = useState([])
    const [contactDetails, SetContactDetails] = useState([]);

    useEffect(() => {
        serverUrl
            .get(`/landlord/contact-apartment-booking/`)
            .then((res) => {
                SetContactDetails(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
        const fetchNearbyFacilities = async () => {
            try {
                const res = await serverUrl.get(`/landlord/alliance-facilities-apartment-allowany?apartment_id=${id}`);
                setNearbyFacilities(res.data.results);
            } catch (error) {
                console.error("Error fetching nearby facilities:", error);
            }
        };

        const fetchHouseApartment = async () => {
            if (id === "all") {
                const saved = localStorage.getItem("searchResultsHouses");
                const initialValue = JSON.parse(saved);
                setHouseApartment(initialValue);
            } else {
                try {
                    const res = await serverUrl.get(`/landlord/alliance-house?property_id=${id}`);
                    setHouseApartment(res.data.results);
                    setSelectedAmenities(res.data.amenities);
                } catch (error) {
                    console.error("Error fetching house apartment:", error);
                }
            }
        };

        const fetchSpecificApartment = async () => {
            try {
                const res = await serverUrl.get(`/landlord/alliance-specific-apartment?property_id=${id}`);
                const apartment = res.data.results[0];
                setSelectedProperty(apartment);
                setSelectedApartmentImages(apartment.property_image);
            } catch (error) {
                console.error("Error fetching specific apartment:", error);
            }
        };

        const fetchUserDetails = async () => {
            if (!accessToken) return;

            try {
                const parsedTokens = JSON.parse(accessToken);
                const config = { headers: { Authorization: `Bearer ${parsedTokens.access}` } };
                const decodedToken = jwtDecode(accessToken);
                const userId = decodedToken.user_id;

                // Fetch current user details
                const userRes = await serverUrl.get(`/users/users-details/${userId}/`, config);
                setCurrentUser(userRes.data);

                // Fetch liked units
                const likesRes = await serverUrl.get(`/landlord/viewers-favourites/?user=${userId}`, config);
                const likedUnits = likesRes.data.results.map((likedUnit) => likedUnit.house);
                const newHeartState = { ...heartState };
                likedUnits.forEach((unitId) => {
                    newHeartState[unitId] = true;
                });
                setHeartState(newHeartState);
            } catch (error) {
                console.error("Error fetching user details or liked units:", error);
            }
        };

        const loadData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchNearbyFacilities(),
                fetchHouseApartment(),
                fetchSpecificApartment(),
                fetchUserDetails(),
            ]);
            setIsLoading(false);
        };

        loadData();
    }, [accessToken, heartState, id]);


    const handlePlatform = (platform) => {
        var Platform = platform;
        // Check if the user is logged in.
        // const parsedTokens = JSON.parse(accessToken);
        // const access = parsedTokens.access;

        // Headers access token.
        // const config = {
        //     headers: { Authorization: `Bearer ${access}` },
        // };

        // Decoding the token to get the user id.
        // const decodedToken = jwtDecode(accessToken);
        // const userId = decodedToken.user_id;

        // const data = {
        //     user: userId,
        //     house: id,
        //     platform: Platform,
        // };

        // serverUrl
        //     .post(`/landlord/shared-units/`, data, config)
        //     .then((res) => {
        //         console.log(res.data.results);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        if (platform === "whatsapp") {
            window.open(whatsappLink, "_blank");
        } else if (platform === "facebook") {
            // Handle Facebook logic here
            // ...
        } else if (platform === "gmail") {
            window.open(EmailLink);
        } else if (platform === "x") {
            window.open(twitterLink, "_blank");
        } else if (platform === "instagram") {
            window.open(instagramLink, "_blank");
        } else if (platform === "copy_link") {
            handleCopyLink();
        }


    };

    // randomise the images for the apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

    // useEffect(() => {
    //     const getRandomImage = () => {
    //         const storedImage = localStorage.getItem('backgroundImage') || ''; 
    //         let randomNumber = Math.floor(Math.random() * 10) + 1;
    //         const invalidNumbers = [0, -1];

    //         // Generate a random number that is valid and does not match the last stored image
    //         while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
    //             randomNumber = Math.floor(Math.random() * 10) + 1;
    //         }

    //         const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
    //         setBackgroundImageApartment(imageUrl);
    //         localStorage.setItem('backgroundImage', imageUrl); // Store the new image
    //         setIsLoading(false);
    //     };

    //     getRandomImage();
    // }, []);
    // Helper function to check if any field in the item contains the search query
    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12;
    const filteredData = housesApartment.filter((item) => {
        // Filter by selected property type if dataFromChild is not blank
        if (dataFromChild && item.type_of_house !== dataFromChild) {
            return false;
        }

        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        return true;
    });

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);
    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // apartment background image
    const backgroundImage = apartmentImage || backgroundImageApartment;
    // house carousel
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 20 }, (_, index) => index + 1);
            const uniqueRandomImages = [];
            let randomNumber = Math.floor(Math.random() * 20) + 1;

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/House/house${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of housesApartment) {
                const apartmentId = unitDetail.house_id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [housesApartment, backgroundImageApartment]);

    const renderCarouselItems = (unitId) => {
        const uniqueImages = housesApartment.filter((house) => house.house_id === unitId)
        const Images = uniqueImages[0].house_image

        return Images.map((imageUrl, index) => (
            <Slider {...settings} key={index}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };

    const toggleHearts = async (houseId) => {
        const prevHeartStateValue = heartState[houseId];

        // Update the UI to immediately reflect the change.
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [houseId]: !prevHeartState[houseId],
        }));

        if (accessToken) {
            // Check if the user is logged in.
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;

            // Headers access token.
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            };

            // Decoding the token to get the user id.
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;

            // If the user has already liked the apartment, delete it.
            if (prevHeartStateValue) {
                try {
                    const res = await serverUrl.get(`/landlord/viewers-favourites/?house=${houseId}&user=${userId}`, config);
                    if (res.data.count === 1) {
                        // If the user has liked the apartment, delete it.
                        await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                // If the user hasn't liked the apartment, add it.
                const likedApartment = {
                    house: houseId,
                    user: userId,
                    user_name: currentUser.full_name,
                };
                try {
                    await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            toast.error('Kindly Login so that you can be able to like a house');
        }
    };

    // randomise the images for my properties
    const [backgroundImageProperty, setBackgroundImageProperty] = useState([]);

    useEffect(() => {
        const getRandomImages = () => {
            const images = [];
            for (let i = 1; i <= 5; i++) {
                let randomNumber = Math.floor(Math.random() * 10) + 1;
                while (randomNumber === 0 || randomNumber === -1 || images.includes(randomNumber)) {
                    randomNumber = Math.floor(Math.random() * 10) + 1;
                }
                images.push(randomNumber);
            }

            const imageUrls = images.map((randomNumber) => `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`);
            setBackgroundImageProperty(imageUrls);
        };

        getRandomImages();
    }, []);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    };

    const [showModalAmmenities, setShowModalAmmenities] = useState(false);

    const handleAmenities = () => {
        setShowModalAmmenities(true)
    }

    const [showModalFacilities, setShowModalFacilities] = useState(false);

    const handleFacilities = () => {
        setShowModalFacilities(true)
    }

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }
    // if the data is loading show a message
    // if (isLoading) {
    //     return (
    //         <div className="loader-container">
    //             <img src={loaderImage} alt="Loading..." />
    //         </div>
    //     );
    // }

    const [bookModal, setBookModal] = useState(false);

    const handleShowBook = () => {
        setBookModal(true);

    };
    const handleCloseBook = () => {
        setBookModal(false);
    };
    const [showModalImageCarousel, setShowModalImageCarousel] = useState(false);

    const [selectedImageIndexCarousel, setSelectedImageIndexCarousel] =
        useState(0);

    const handleImageClickImageCarousel = (index) => {
        setSelectedImageIndexCarousel(index);
        setShowModalImageCarousel(true);
    };

    const handleCloseModalImageCarousel = () => {
        setShowModalImageCarousel(false);
    };

    const handleImageSelectImageCarousel = (index) => {
        setSelectedImageIndexCarousel(index);
    };
    return (
        <>
            <NavigationBar onData={handleSearchData} {...props} />
            {/* <TopMenuFilter onData={handleChildData} {...props} /> */}
            <div className='dashboard_topheader'></div>
            <div className='selected-property'>
                <div className='d-flex justify-content-between'>
                    <Button
                        className="btn mt-2  btn-success text-white"
                        onClick={handleShowBook}
                    >
                        Contact Us
                    </Button>
                    <div className='mt-3'>
                        <Link
                            className="text-dark"
                            style={{ fontSize: "0.9rem", marginRight: "1.5rem" }}
                            onClick={() => handleShow()}
                        >
                            <i className="bi bi-upload"></i> Share
                        </Link>

                        <Link
                            className="text-dark"
                            onClick={() => toggleHearts(id)}
                            style={{ fontSize: "0.9rem" }}
                        >
                            {heartState[id] ? (
                                <i className="bi bi-heart-fill text-warning"></i>
                            ) : (
                                <i className="bi bi-heart"></i>
                            )}
                            Save
                        </Link>
                    </div>
                </div>
                {/* top share like */}
                <div className="shareSave mb-2" style={{ float: "inline-end" }}>


                    <Modal size="lg" show={showShareModal} onHide={() => setShowShareModal(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Share this property details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("whatsapp")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid lightgray",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "lightgray")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Whatsapp}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on WhatsApp</p>
                                    </Col>
                                </div>

                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("facebook")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid lightgray",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "lightgray")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                        onClick={() => shareFacebook()}
                                    >
                                        <Image
                                            src={Facebook}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Facebook</p>
                                    </Col>
                                </div>
                            </Row>

                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("gmail")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid lightgray",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "lightgray")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Mail}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Gmail</p>
                                    </Col>
                                </div>

                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("x")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid lightgray",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "lightgray")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Twitter}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Twitter</p>
                                    </Col>
                                </div>
                            </Row>

                            <Row className="d-flex">
                                <div
                                    className="text-decoration-none text-dark w-50"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handlePlatform("instagram")}
                                >
                                    <Col
                                        md={6}
                                        className="d-flex"
                                        style={{
                                            border: "1px solid lightgray",
                                            width: "91%",
                                            marginInline: "0.5rem",
                                            marginTop: "1rem",
                                            borderRadius: "10px",
                                            cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) =>
                                            (e.currentTarget.style.backgroundColor = "lightgray")
                                        }
                                        onMouseLeave={(e) =>
                                            (e.currentTarget.style.backgroundColor = "inherit")
                                        }
                                    >
                                        <Image
                                            src={Instagram}
                                            className="mb-2 me-2 mt-2 ms-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Share on Instagram</p>
                                    </Col>
                                </div>

                                <Col
                                    md={6}
                                    style={{
                                        border: "1px solid lightgray",
                                        width: "44%",
                                        marginInline: "1.1rem",
                                        marginTop: "1rem",
                                        borderRadius: "10px",
                                        cursor: "pointer",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.currentTarget.style.backgroundColor = "lightgray")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.currentTarget.style.backgroundColor = "inherit")
                                    }
                                    onClick={() => handlePlatform("copy_link")}
                                >
                                    <div className="d-flex">
                                        <Image
                                            src={Paperclip}
                                            className="mb-2 me-2 mt-2"
                                            style={{
                                                borderRadius: "10px",
                                                width: "auto",
                                                height: "5vh",
                                            }}
                                        />
                                        <p className="mt-3">Copy the link </p>
                                    </div>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowShareModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </div>

                {/* end of top share like */}

                {/* contact us modal */}
                <Modal show={bookModal} onHide={handleCloseBook} centered size="md">
                    <Modal.Header closeButton>
                        Contact us for more information about {selectedPropertyDetails.name}
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>Contact Info</p>
                            <div className="d-flex">
                                {contactDetails.map((contact) => (
                                    <>
                                        <a
                                            href={`tel:${contact.contact_number}`}
                                            className="btn btn-sm btn-warning text-white mx-3"
                                        >
                                            Call {contact.contact_number}
                                        </a>
                                    </>
                                )
                                )}
                            </div>

                            <div className="h-75 mt-2">
                                <p>{selectedPropertyDetails.name} Images</p>
                                <Carousel
                                    activeIndex={selectedImageIndexCarousel}
                                    onSelect={handleImageSelectImageCarousel}
                                >
                                    {selectedApartmentImages.map((image, index) => (
                                        <Carousel.Item key={index} style={{ height: "50vh" }}>
                                            <Image
                                                src={image.image}
                                                fluid
                                                className="topCarouselselectedHouse imageDisplayBooking"
                                                style={{ objectFit: "cover", height: "50vh" }}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                        {/* <Form onSubmit={handleBooking}>
                  <FormControl type='text' placeholder="Full Name" name='full_name' className="mt-3" value={formDataBook.full_name} onChange={handleChangeBook} required />
                  <FormControl type='phone' placeholder="Phone Number" name='phone_number' className="mt-3" value={formDataBook.phone_number} onChange={handleChangeBook} required />
                  <FormControl type='email' placeholder="Your Email" className="mt-3" name='email' value={formDataBook.email} onChange={handleChangeBook} required />
                  <label className="mt-2">Date of viewing</label>
                  <FormControl type='date' className="mt-3" name='starting_date' value={formDataBook.starting_date} onChange={handleChangeBook} required />
                  <label className="mt-2">Start Time</label>
                  <FormControl type='time' className="mt-3" name='starting_time' value={formDataBook.starting_time} onChange={handleChangeBook} required />
                  <label className="mt-2">Duration</label>
                  <FormControl type='number' className="mt-3" name='duration' value={formDataBook.duration} onChange={handleChangeBook} required />
                  <Button className='btn btn-sm btn-warning text-white mt-3' type='submit'>Save Booking</Button>
                </Form> */}
                    </Modal.Body>
                    {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseBook}>
                  Make Call
                </Button>
              </Modal.Footer> */}
                </Modal>
                <div className='topSelectedProperty'>
                    {selectedApartmentImages.length > 0 && (
                        <Carousel >
                            {selectedApartmentImages.map((imageUrl, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        src={imageUrl.image}
                                        className="propertyCarousel"
                                        onClick={() => handleImageClick(index)}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                    <div className='ImageHouseName'>
                        {selectedApartment && (<>
                            <span className='properties'>{selectedPropertyDetails.location},{selectedPropertyDetails.county}</span>
                            <h2>{selectedPropertyDetails.name}</h2>
                            <Button className='text-white bg-dark border-0 bottomButtonMap' href={`/#/map/${id}`}>
                                <Link to={`/map/${id}`} className='text-white text-decoration-none'>
                                    Show location
                                    <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                                </Link>
                            </Button>

                            <Button className='btn btn-warning btn-sm w-10 text-white selectedPropertyShowAll'>
                                <Link to={`/all-photos-propert/${selectedPropertyDetails.id}`} className='text-white text-decoration-none ms-2'>
                                    <Image src={Grid} style={{ width: "9%", height: "2vh" }} /> Show all photos                        {selectedApartment.property_image}
                                </Link>
                            </Button>
                        </>)}
                    </div>
                </div>

                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                    dialogClassName='modal-100w'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Apartment View Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Carousel
                            activeIndex={selectedImageIndex}
                            onSelect={(index) => setSelectedImageIndex(index)}
                        >
                            {backgroundImageProperty.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <Image src={image} alt={`Image ${index}`} fluid />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className='d-flex w-25 mt-5  ButtonsTopAmenities'>
                    <Button className='btn btn-sm btn-warning text-white ms-1' onClick={() => handleAmenities()}>Amenities</Button>
                    <Button className='btn btn-sm btn-warning text-white ms-3' onClick={() => handleFacilities()}>Nearby Facilities</Button>
                </div>
                {/* ammenities modal */}
                <Modal
                    show={showModalAmmenities}
                    onHide={() => setShowModalAmmenities(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "#E6B800" }}>Amenities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {selectedAmenities.length > 0 ?
                                selectedAmenities.map((amenity, index) => (
                                    <Col key={index} md={6} className="amenties text-start d-flex">
                                        <p className='ms-3 amenityText'> {amenity.name}</p>

                                        <Image src={amenity.icon} style={{ width: "10%", marginLeft: "0.5rem", marginBottom: "1rem" }} />
                                    </Col >
                                ))
                                :
                                <p style={{ color: "#95a5a6" }}>No amenities found</p>
                            }


                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModalAmmenities(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* nearby Facilities */}
                <Modal
                    show={showModalFacilities}
                    onHide={() => setShowModalFacilities(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "#E6B800" }}>Nearby Facilities</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            {nearbyFacilities.length > 0 ?
                                nearbyFacilities.map((essential, index) => (
                                    <Col key={index} md={6} className='d-flex'>
                                        <Image src={essential.icon_image} style={{ width: "10%", height: "55%", marginTop: "0.9rem", marginLeft: "0.7rem" }} />
                                        <label style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }} className='amenityText'><span>{essential.icon}</span> is {essential.distance} meters away</label>

                                    </Col>

                                ))
                                :
                                <p style={{ color: "#95a5a6" }}>No nearby facilities were found</p>

                            }



                        </Row>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModalFacilities(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Col md={9} sm={6} className='d-flex w-100' style={{ height: 'fit-content' }}>
                        {housesApartment.length === 0 ?
                            <p className='notfoundp'>NO UNITS FOUND</p>
                            :
                            displayedItems.map((unitDetail, index) =>
                                <>
                                    <Card className='house' key={index}>
                                        <Card.Body>
                                            <div className="image-container">
                                                <Link to={`/selected-house/${unitDetail.house_id}`} className='text-decoration-none'>

                                                    <Slider
                                                        {...settings}
                                                        interval={null}
                                                        className="houseUnit">{renderCarouselItems(unitDetail.house_id)}
                                                    </Slider>
                                                </Link>

                                                <div className="badge text-capitalize">{unitDetail.rent_type}</div>

                                                <div className='heartBadge' onClick={() => toggleHearts(unitDetail.house_id)}>
                                                    {heartState[unitDetail.house_id] ? (
                                                        <div className='' style={{ width: "2vw", borderRadius: "8px" }} >
                                                            <img src={Heart} alt="Heart" />
                                                        </div>

                                                    ) : (
                                                        <div className='' style={{ width: "2vw", borderRadius: "8px" }} >
                                                            <img src={Heart2} alt="Heart2" />
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <Link to={`/selected-house/${unitDetail.house_id}`} className='text-decoration-none'>

                                                    <div className="badge_rent text-capitalize">{toCamelCase(unitDetail.type_rent_status)}</div>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="text-start houseTitle" style={{ marginBottom: "10px" }} ></p>
                                                    </div>
                                                </Link> */}
                                                <div className="badge_rent text-capitalize">{toCamelCase(unitDetail.type_rent_status)}</div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="text-start houseTitle" style={{ marginBottom: "10px" }} >{unitDetail.name}</p>
                                                </div>

                                            </div>
                                            {unitDetail.type_rent_status === 'for_sale' ?
                                                <div className="houseRentP">Ksh {unitDetail.rent.toLocaleString()}</div>
                                                :
                                                <div className="houseRentP">Ksh {unitDetail.rent.toLocaleString()}/month</div>


                                            }
                                            <p className="houseName fw-normal text-capitalize" style={{ marginBottom: "20px", position: "absolute", bottom: "6%" }}>{toCamelCase(unitDetail.type_of_house)}</p>
                                            <div className="d-flex" style={{ paddingTop: "0.8rem" }}>
                                                <span className='d-flex' style={{ marginTop: ":1rem" }}>
                                                    <span style={{ fontSize: "0.8rem", marginRight: "0.4rem", marginTop: ":1rem" }}>{unitDetail.bedroom} Bedrooms</span>
                                                    <span style={{ fontSize: "1.8rem", marginTop: "-1.2rem" }}>.</span>
                                                    {unitDetail.bathroom === 1001 ?
                                                        <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>All Ensuite</span>
                                                        : unitDetail.bathroom === 1111 ?
                                                            <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>Master Ensuite</span>
                                                            :
                                                            <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>{unitDetail.bathroom} Bathrooms</span>
                                                    }
                                                </span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </>
                            )
                        }
                        {housesApartment.length === 0 ?
                            <></>
                            :
                            <div className="pagination" style={{ margin: '0 auto', padding: "2rem", width: 'inherit' }}>
                                <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                    &lt;
                                </button>
                                <span style={{ alignSelf: "center" }}>{`${startIndex + 1}-${endIndex} of ${housesApartment.length}`}</span>
                                <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                    &gt;
                                </button>
                            </div>
                        }

                    </Col>
                    <Col md={3} sm={8} className='d-none'>
                        <Card className='topProperty'>
                            <Row>
                                <Col md={12} className='fw-bold mb-2 fs-6 text-start' style={{ color: "#E6B800" }}>
                                    AMENITIES
                                </Col>
                            </Row>
                            <Row>
                                {amenitiesApartment.length > 0 ?
                                    amenitiesApartment.map((amenity, index) => (
                                        <Col key={index} md={12} className="amenties text-start d-flex">
                                            <Image src={amenity.icon} style={{ width: "15%", marginBottom: "1rem" }} />
                                            <p className='ms-3 amenityText'> {amenity.name}</p>
                                        </Col >
                                    ))
                                    :
                                    <p style={{ color: "#95a5a6" }}>No amenities found for this property kindly add one</p>
                                }
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div >


        </>
    )
}
export default SelectedProperty