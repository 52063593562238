import React from 'react'
import '../assets/scss/OurPartners.scss'
import Usalama from '../assets/images/usalama_logo_red_large.png'
import Kodi from '../assets/images/Kodi.jpeg'


function OurPartners() {
  return (
    <div className='partners'>
      <h2>Our Partners</h2>
      <p className=''>We only work with the best companies around the globe</p>

      <a href="https://usalama.app/">
        <img src={Usalama} style={{width:"5%"}} alt="Usalama" />
      </a>
      <a href="https://www.kodinyumba.com/"> 
        <img src={Kodi} style={{width:"10%"}} alt="Kodi" />
      </a>
      {/* <p>Usalama Technologies</p> */}

    </div>
  )
}
export default OurPartners
