import { Helmet } from 'react-helmet-async';

export function SEO({ 
  title, 
  description, 
  keywords = `property management, rental properties Kenya, apartments for rent, rental homes Kenya, bedsitter Kenya, studio apartments Kenya, one-bedroom apartments Kenya, two-bedroom apartments Kenya, Nairobi rentals, Mombasa rentals, Nakuru apartments, Kenya rental listings, affordable apartments Kenya, furnished apartments Kenya, luxury apartments Kenya, rental houses Kenya, short-term rentals Kenya, long-term rentals Kenya, county rentals Kenya, apartment listings Kenya, real estate Kenya, house rentals, rent bedsitter Kenya, rental properties Nairobi, Kisumu rentals, Eldoret apartments, studio apartments Nairobi, affordable housing Kenya, find rental homes Kenya, property listings Kenya, apartments near me Kenya, real estate listings Kenya  `,
    ogImage = "https://www.alliancerealtorsltd.co.ke/static/media/logo_alliance.264c5944e4cc20f18be6.png",
  ogUrl = window.location.href 
}) {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content="website" />
    
      
      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <link rel="canonical" href={ogUrl} />
    </Helmet>
  );
}
