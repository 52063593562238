import './App.css';
import Webroutes from './Webroutes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SEO } from './SEO';

function App() {
  return (
    <div className="App">
      <SEO
        title="Alliance Realtors Ltd - Real Estate Agency | Property Listings for Sale & Rent"
        description="Alliance Realtors Ltd is a top real estate agency in Kenya, offering a wide range of properties for sale and rent. Find your perfect home, investment, or commercial space today."

      keywords = "property management,Alliance, rental properties Kenya, apartments for rent, rental homes Kenya, bedsitter Kenya, studio apartments Kenya, one-bedroom apartments Kenya, two-bedroom apartments Kenya, Nairobi rentals, Mombasa rentals, Nakuru apartments, Kenya rental listings, affordable apartments Kenya, furnished apartments Kenya, luxury apartments Kenya, rental houses Kenya, short-term rentals Kenya, long-term rentals Kenya, county rentals Kenya, apartment listings Kenya, real estate Kenya, house rentals, rent bedsitter Kenya, rental properties Nairobi, Kisumu rentals, Eldoret apartments, studio apartments Nairobi, affordable housing Kenya, find rental homes Kenya, property listings Kenya, apartments near me Kenya, real estate listings Kenya"
        />

      <ToastContainer />

      <Webroutes />
    </div>
  );
}

export default App;
