/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Col, Image, Row } from 'react-bootstrap';
import Image1 from '../../assets/images/dummy/image5.png'
import Image2 from '../../assets/images/dummy/image10.png'
import Image3 from '../../assets/images/dummy/image2.png'
import '../../assets/scss/CompetitiveCarousel.scss'
function CompetitiveCarousel() {
  const items = [
    {
      description: 'WE DO IT DIFFERENTLY',
      data: "Our Real Estate Agents are at your service with their accumulated knowledge of years and experience ",
      image: Image3,
    },
    {
      description: 'FREE VIEWING',
      data: "Our most important priority in all our services is the satisfaction of our customers",
      image: Image2,
    },
    {
      description: 'ANCHORED ON EXCELLENCE & INTEGRITY',
      data: "",
      image: Image1

    },

  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, 
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

        }
      }
    ]
  };
  return (
    <div className='competitiveCarousel'>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className='px-5 topViewDisplay' >
            <div className='carouselParagraph'>
              <h2>{item.description}</h2>
              <p>{item.data}</p>
            </div>

            <Image src={item.image} />

          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CompetitiveCarousel