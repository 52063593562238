import React, { useEffect, useState } from 'react';
import '../assets/scss/FeaturedProperties.scss';
import { Card, Col, Image, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { serverUrl } from '../serverUrl';

function FeaturedProperties() {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                serverUrl.get(`/landlord/all-landlords`)
                    .then((res) => {
                        setProperties(res.data.results.filter(apartment => apartment.is_featured === true));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (error) {
                console.error("Error fetching apartments:", error);
            }
        };

        fetchData();
    }, []);

    const slidesToShow = properties.length < 4 ? properties.length : 4;
    const settings = {
        dots: true, // Show dots as indicators
        infinite: properties.length > 1,
        speed: 1000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 2000,
        // autoplaySpeed: 20000000,
        arrows: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }

    return (
        <div className='featuredProperties bg-light'>
            {properties.length > 0 && (
                <>
                    <h2>Featured Properties</h2>
                    <p>Handpicked properties by our team.</p>
                    <Link to='/listing-page/Featured' style={{ cursor: "pointer" }} className='viewALL'>View all</Link>
                </>
            )}

            <Row className='mt-1 justify-content-between'>
                <Slider {...settings}>
                    {properties.map((property) => (
                        <Col md={3} lg={3} sm={6} xs={12} style={{ width: "auto" }} key={property.id}>
                            <Card>
                                <Link to={`/selected-property/${property.id}`} className='text-decoration-none text-dark'>
                                    <Card.Body>
                                        <Image src={property.property_cover_photo} className='propertyImage' />
                                        <div className='d-flex'>
                                            <div className='d-flex topFeaturedButtons'>
                                                <span className='topFeaturedProperties'>Featured</span>
                                            </div>
                                            <div className='d-flex topFeaturedButtonsLast'>
                                                <span className='topRentProperties text-capitalize'>{toCamelCase(property.type_of_rent)}</span>
                                            </div>
                                        </div>
                                        <span className='textRentProperty'>{property.name}</span>
                                        <p className='mt-3' style={{ fontSize: '0.75rem' }}>
                                            <i className='bi bi-geo-alt'></i>
                                            <span>{property.location}</span>
                                        </p>
                                        {property.houses?.slice(0, 3).map((house) => (
                                            <h6 className='text-capitalize' key={house.id}>
                                                {house.house_bedroom} : Ksh.{parseInt(house.house_rent).toLocaleString()}/month
                                            </h6>
                                        ))}
                                        <div className='d-flex'>
                                            <Link to={`/selected-property/${property.id}`} className='text-dark'>
                                                View Property
                                            </Link>
                                        </div>
                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>
                    ))}
                </Slider>
            </Row>
        </div>
    );
}

export default FeaturedProperties;
