/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import '../assets/scss/GetStartedOffcanvas.scss'
function GetStartedOffcanvas({ show, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        location: '',
        email: '',
        services: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle form submission logic here
        console.log('Form data submitted:', formData);
        // Close the off-canvas after submission
        onClose();
    };
    return (
        <div>
            <div
                className={`offcanvas offcanvas-end ${show ? 'show' : ''}`}
                tabIndex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title text-success " id="offcanvasExampleLabel">
                        GET STARTED TODAY
                    </h5>
                    <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={onClose}
          ></button>
                </div>
                <div className="offcanvas-body">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="location">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="services">
                            <Form.Label>Services</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="services"
                                value={formData.services}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <div className='d-flex mt-2 justify-content-between'>
                        <Button variant="warning" type="submit" className='text-white'>
                            Submit
                        </Button>
                        <Button variant='secondary' onClick={onClose}>
                        Close
                    </Button>
                        </div>
                      
                    </Form>                   
                    
                </div>
            </div>
        </div>
    );
}

export default GetStartedOffcanvas;
