import React, { useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import { Container, Row, Col, Image } from "react-bootstrap";
import {  useParams } from "react-router-dom";
import { serverUrl } from "../serverUrl";

function Allphotos() {
  const { id } = useParams();
  const hash = window.location.hash;
  const currentUrl = hash.substring(1);
  const [apartmentImages, setApartmentImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (currentUrl.includes("unit")) {
          response = await serverUrl.get(
            `/landlord/alliance-unit-images?house_id=${id}`
          );
        } else {
          response = await serverUrl.get(
            `/landlord/alliance-property-images?property_id=${id}`
          );
        }

        setApartmentImages(response.data.images);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, currentUrl]);

  const goBack = () => {
    window.history.back();
  };

  // Show a loading spinner while fetching data
  if (isLoading) {
    return (
      <div className="text-center">
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  // Function to render images for the property layout
  const renderPropertyImages = () => {
    return apartmentImages.map((image, index) => (
      <Col
      key={index}
      // md={12}
      md={index % 3 === 0 ? 12 : 6}
      style={{
        // marginTop: index % 3 === 0 ? "-12rem" : "-25.5rem",
        marginBottom:"1rem",
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
      }}
    >
      <Image 
        src={image.image} 
        alt={`Image ${index}`} 
        style={{
          width: '100%', 
          height: '70vh',
          objectFit: 'cover',
        }}
      />
    </Col>
    
    ));
  };

  const sortedApartmentImages = [...apartmentImages].sort((a, b) => {
    if (a.room_name === "Others") return 1;
    if (b.room_name === "Others") return -1;
    return 0;
  });

  // Function to render images for the unit layout
  const renderUnitImages = () => {
    // Render images with accordion layout
    return (
      <>
        {sortedApartmentImages.map((room, index) => (
          <Col key={index} md={12}>
            <div
              className="accordion"
              style={{ marginLeft: "-20rem", width: "110vwx" }}
            >
              <div className="accordion-item mb-5 d-flex border-0">
                <h2 className="accordion-header" id={`heading-${index}`}>
                  <button
                    className="accordion-button"
                    style={{ width: "15vw" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${index}`}
                  >
                    {room.room_name}
                  </button>
                </h2>
                <div
                  id={`collapse-${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby={`heading-${index}`}
                  data-bs-parent=".accordion"
                >
                  <div
                    className="accordion-body d-flex"
                    style={{
                      overflow: "auto",
                      marginLeft: "7rem",
                      width: "90%",
                    }}
                  >
                    {room.images.map((imageUrl, imageIndex) => (
                      <Image
                        key={imageIndex}
                        src={imageUrl}
                        className="w-100 mx-3"
                        style={{ height: "50vh" }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </>
    );
  };

  return (
    <>
      <p className="topNavigation" onClick={goBack}>
        <i className="bi bi-arrow-left-circle"></i>
      </p>
      <NavigationBar />
      <div className="all-photos">
        <Container className="mb-3 mt-5">
          <Row>

            {currentUrl.includes("unit")
              ? renderUnitImages()
              : renderPropertyImages()}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Allphotos;
